<template>
  <div class="changepsw_box">
    <div class="changepsw_tips">
      <p class="tips">设置账号 <span class="userPhone">{{phone}}</span> 的新密码</p>
    </div>
    <ul class="ft_changepsw_box">
      <li class="changepsw_item">
        <input class="fl psw_code"
               :type="pswtype"
               :placeholder="$t('toast.newverifypswTips')"
               v-model="password">
        <span class="fr v_btn"
              @click="pswVisble">
          <img :src="imgUrl"
               alt="" />
        </span>
      </li>
      <li class="changepsw_item">
        <input class="fl psw_code"
               :type="repeatpswtype"
               :placeholder="$t('toast.confirmPswPlaceholder')"
               v-model="repeatpsw">
        <span class="fr v_btn"
              @click="repeatpswVisble">
          <img :src="imgUrl1"
               alt="" />
        </span>
      </li>
      <li class=" changepsw_submit"
          @click="submitAccount">
        {{$t('toast.comfirmBtn2')}}
      </li>
    </ul>
  </div>
</template>

<script>
import { getSessionStorage, setLocalStorage, setToken } from '@/utils/stage'
import { forgetPassword, key } from '@/api/login'
import { encrypt, encodeBase64, HmacSHA256, randomString } from '@/utils/crypto'
export default {
  data() {
    return {
      accountInfo: {},
      password: '',
      verify_code: '',
      repeatpsw: '',
      pswtype: 'password',
      repeatpswtype: 'password',
      repeatpswVisbleFlag: false,
      pswVisbleFlag: false,
      imgUrl: require('@/assets/images/invisible_icon.png'),
      imgUrl1: require('@/assets/images/invisible_icon.png'),
      phone: '',
      configData: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.phone = JSON.parse(getSessionStorage('accountInfo')).phone
      this.phone_token = JSON.parse(
        getSessionStorage('accountInfo')
      ).phone_token
      this.verify_code = getSessionStorage('accountveify')
      this.configData = JSON.parse(getSessionStorage('message'))
    },
    // 查看密码
    pswVisble() {
      this.pswVisbleFlag = !this.pswVisbleFlag
      if (this.pswVisbleFlag) {
        this.imgUrl = require('@/assets/images/visible_icon.png')
        this.pswtype = 'text'
      } else {
        this.imgUrl = require('@/assets/images/invisible_icon.png')
        this.pswtype = 'password'
      }
    },
    repeatpswVisble() {
      this.repeatpswVisbleFlag = !this.repeatpswVisbleFlag
      if (this.repeatpswVisbleFlag) {
        this.imgUrl1 = require('@/assets/images/visible_icon.png')
        this.repeatpswtype = 'text'
      } else {
        this.imgUrl1 = require('@/assets/images/invisible_icon.png')
        this.repeatpswtype = 'password'
      }
    },
    // checkoutPsw () {
    //   // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
    //   const reg = /^[0-9a-zA-Z]{6,18}$/
    //   if (!reg.test(this.password)) {
    //     this.$toast('密码需为6-18位非中文/空格字符')
    //     return
    //   }
    // },
    repeatPsw() {
      if (this.repeatpsw != this.password) {
        this.$toast(this.$t('toast.confirmPswTips'))
      }
    },
    // 注册账号
    submitAccount() {
      if (this.password === '') {
        this.$toast(this.$t('toast.nopsw'))
        return
      }
      if (this.repeatpsw != this.password) {
        this.$toast(this.$t('toast.confirmPswTips'))
        return
      }
      const reg =
        /^[0-9a-zA-Z`~!@#$%^&*()+=|\[\]{}\':;,.<>?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、]{6,18}$/
      // const reg = /^[a-zA-Z0-9`~!@#$%^&*()+=|\\[\\]{}\':;,.<>?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、]{6,18}/
      // const reg = /^[0-9a-zA-Z]{6,18}$/
      if (!reg.test(this.password)) {
        this.$toast(this.$t('toast.verifypswTips'))
        return
      }
      const msg1 = Object.assign(
        JSON.parse(JSON.stringify(this.configData)),
        {}
      )
      const iv = randomString(16)
      const iv_data = encodeBase64(iv)
      key(msg1)
        .then((res) => {
          this.ft_key = res.data.ft_key
          this.encrypted_data = encrypt(this.password, this.ft_key, iv)
          const mac = iv_data + this.encrypted_data
          this.mac_data = HmacSHA256(mac, this.ft_key)
        })
        .then(() => {
          const passport = {
            body: this.encrypted_data,
            iv: iv_data,
            mac: this.mac_data,
          }
          const password = encodeBase64(JSON.stringify(passport))
          const data = {
            phone_token: this.phone_token,
            csrf_token: this.verify_code,
            // body: this.encrypted_data,
            identity_no: this.identity_no,
            real_name: this.real_name,
            token: this.token,
            // iv: iv_data,
            // mac: this.mac_data,
            password: password,
            ft_key: this.ft_key,
          }
          const msg = Object.assign(
            JSON.parse(JSON.stringify(this.configData)),
            data
          )
          forgetPassword(msg).then((res) => {
            if (res.errcode === 0) {
              setLocalStorage(
                'accountStorage',
                JSON.stringify(res.data.user_entity)
              )
              this.$router.push({ path: '/safetySettings' })
              setToken('login', 'success', 7)
            } else if (res.errcode === 6) {
              this.$toast(res.errmsg)
              this.$router.push({ path: '/findAccountGuide' })
            }
          })
        })
    },
  },
}
</script>
<style lang="scss" scope>
@media (min-width: 1600px) {
  .changepsw_box {
    width: 700px;
    min-width: 500px;
    // height: 720px;
    background: #fff;
    margin: 0 auto;
    padding: 60px;
    padding-bottom: 200px;
    box-sizing: border-box;
    .changepsw_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33px;
        color: #575757;
      }
      .userPhone {
        font-size: 22px;
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }
    .ft_changepsw_box {
      .changepsw_item {
        width: 100%;
        height: 60px;
        border: 1px solid #cfcfcf;
        opacity: 1;
        border-radius: 6px;
        margin-top: 40px;
        padding: 10px 34px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1px solid #000;
        }
        .psw_code {
          width: 400px;
          height: 100%;
          font-size: 20px;
          outline: none;
        }
        .v_btn {
          width: 60px;
          height: 60px;
          img {
            width: 36px;
            margin: 0 auto;
          }
          cursor: pointer;
        }
      }
      .changepsw_submit {
        margin-top: 50px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6px;
        letter-spacing: 0px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200px;
        position: relative;
        .verify_input {
          border-right: 1px solid #cfcfcf;
        }
        .verify_btn {
          width: 200px;
          outline: none;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .changepsw_box {
    width: 700 * 0.85px;
    min-width: 500 * 0.85px;
    // height: 720*.85px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.85px;
    padding-bottom: 200 * 0.85px;
    box-sizing: border-box;
    .changepsw_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22 * 0.85px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33 * 0.85px;
        color: #575757;
      }
      .userPhone {
        font-size: 22 * 0.85px;
        margin-top: 24 * 0.85px;
        margin-bottom: 50 * 0.85px;
      }
    }
    .ft_changepsw_box {
      .changepsw_item {
        width: 100%;
        height: 60 * 0.85px;
        border: 1 * 0.85px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.85px;
        margin-top: 40 * 0.85px;
        padding: 10 * 0.85px 34 * 0.85px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.85px solid #000;
        }
        .psw_code {
          width: 400 * 0.85px;
          height: 100%;
          font-size: 20 * 0.85px;
          outline: none;
        }
        .v_btn {
          width: 60 * 0.85px;
          height: 60 * 0.85px;
          img {
            width: 36 * 0.85px;
            margin: 0 auto;
          }
          cursor: pointer;
        }
      }
      .changepsw_submit {
        margin-top: 50 * 0.85px;
        height: 60 * 0.85px;
        line-height: 60 * 0.85px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.85px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.85px;
        letter-spacing: 0 * 0.85px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.85px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.85px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.85px;
          outline: none;
          height: 60 * 0.85px;
          line-height: 60 * 0.85px;
          font-size: 20 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
@media (min-width: 500px) and (max-width:1199px) {
  .changepsw_box {
    width: 700 * 0.7px;
    min-width: 500 * 0.7px;
    // height: 720*.7px;
    background: #fff;
    margin: 0 auto;
    padding: 60 * 0.7px;
    padding-bottom: 200 * 0.7px;
    box-sizing: border-box;
    .changepsw_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22 * 0.7px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33 * 0.7px;
        color: #575757;
      }
      .userPhone {
        font-size: 22 * 0.7px;
        margin-top: 22 * 0.7px;
        margin-bottom: 50 * 0.7px;
      }
    }
    .ft_changepsw_box {
      .changepsw_item {
        width: 100%;
        height: 60 * 0.7px;
        border: 1 * 0.7px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.7px;
        margin-top: 40 * 0.7px;
        padding: 10 * 0.7px 34 * 0.7px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.7px solid #000;
        }
        .psw_code {
          width: 400 * 0.7px;
          height: 100%;
          font-size: 20 * 0.7px;
          outline: none;
        }
        .v_btn {
          width: 60 * 0.7px;
          height: 60 * 0.7px;
          img {
            width: 36 * 0.7px;
            margin: 0 auto;
          }
          cursor: pointer;
        }
      }
      .changepsw_submit {
        margin-top: 50 * 0.7px;
        height: 60 * 0.7px;
        line-height: 60 * 0.7px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.7px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.7px;
        letter-spacing: 0 * 0.7px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.7px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.7px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.7px;
          outline: none;
          height: 60 * 0.7px;
          line-height: 60 * 0.7px;
          font-size: 20 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
@media screen and (max-width:499px) {
  .changepsw_box {
    width: 380px;
    min-width: 500 * 0.6px;
    // height: 720*.7px;
    background: #fff;
    border-radius: 6px;
    margin: 0 auto;
    padding: 60 * 0.6px;
    padding-bottom: 200 * 0.6px;
    box-sizing: border-box;
    .changepsw_tips {
      width: 100%;
      text-align: center;
      .tips {
        font-size: 22 * 0.6px;
        font-family: SimHei;
        font-weight: 400;
        line-height: 33 * 0.6px;
        color: #575757;
      }
      .userPhone {
        font-size: 22 * 0.6px;
        margin-top: 22 * 0.6px;
        margin-bottom: 50 * 0.6px;
      }
    }
    .ft_changepsw_box {
      .changepsw_item {
        width: 100%;
        height: 60 * 0.6px;
        border: 1 * 0.6px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.6px;
        margin-top: 40 * 0.6px;
        padding: 10 * 0.6px 34 * 0.6px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.6px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.7px solid #000;
        }
        .psw_code {
          width: 400 * 0.55px;
          height: 100%;
          font-size: 20 * 0.6px;
          outline: none;
        }
        .v_btn {
          width: 60 * 0.6px;
          height: 60 * 0.6px;
          img {
            width: 36 * 0.6px;
            margin: 0 auto;
          }
          cursor: pointer;
        }
      }
      .changepsw_submit {
        margin-top: 50 * 0.6px;
        height: 60 * 0.6px;
        line-height: 60 * 0.6px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.6px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.6px;
        letter-spacing: 0 * 0.6px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.6px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.6px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.6px;
          outline: none;
          height: 60 * 0.6px;
          line-height: 60 * 0.6px;
          font-size: 20 * 0.6px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
